import React from 'react';

import Markdown from 'components/common/markdown/Markdown';
import textHook from 'hooks/text/text.hook';
import { getFieldBySlug } from 'utils/directus';
import { Props } from './Text.interface';

import s from './Text.scss';

export default ({ slug, margin, baseFontSize, listItemIcon, marginBottom }: Props): JSX.Element => {
    const { body } = getFieldBySlug(slug, textHook()).node;
    const marginStyle = margin ? s.margin : marginBottom ? s.marginBottom : undefined;

    return (
        <div className={marginStyle} style={{ fontSize: baseFontSize }}>
            <Markdown source={body} className={s.body} listItemIcon={listItemIcon} container />
        </div>
    );
};
